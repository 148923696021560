import _ from 'lodash';
import { colors } from '@commonsku/styles';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import * as am4plugins_sliceGrouper from "@amcharts/amcharts4/plugins/sliceGrouper";
import * as am4plugins_sunburst from "@amcharts/amcharts4/plugins/sunburst";
import { PinIcon,TrashIcon } from '@commonsku/styles';

/**
 * @typedef {"number"|"currency"|"percent"|"percentage"} NumberFormatType
 * @typedef {am4charts.Chart|am4charts.TreeMap|am4charts.XYChart|am4charts.PieChart|am4charts.GaugeChart|am4charts.RadarChart|am4charts.SlicedChart|am4charts.SerialChart|am4core.Container} Chart
 * @typedef {{
 *    data: Array,
 *    name: String, // used in TreeMap label
 *    value: String, // used in TreeMap value (size of box)
 *    valueX: String|undefined, // x-value used in XY charts
 *    valueY: String|undefined, // y-value used in XY charts
 *    imgField: String|undefined,
 *    imgHref: String|undefined,
 *    imgHeight: String|Number|undefined,
 *    imgWidth: String|Number|undefined,
 *    tooltipText: String|undefined,
 *    children: Array|undefined,
 *    [key: string]: any,
 *    xAxes: Array<Object>,
 *    yAxes: Array<Object>,
 *    cursor: Object,
 *    legend: Object,
 *    hoverable: Boolean,
 *    colors: Object,
 *    isSparkline: Boolean,
 *    strokeWidth: Number
 * }} ChartOptions
 */

export const colorPalette = [
  colors.primary, colors.cta, colors.special1, colors.special2,
  colors.primary10, colors.primary0, colors.primary100, colors.error
];

export const OrderTypesColor = {
  OPPORTUNITY: {
    main: colors.orders.OPPORTUNITY.main,
  },
  PRESENTATION: {
    main: colors.orders.PRESENTATION.main,
  },
  ESTIMATE: {
    main: colors.orders.ESTIMATE.main,
  },
  'PRE-SALES': {
    main: colors.orders['PRE-SALES'].main,
  },
  'SALES ORDER': {
    main: colors.orders['SALES ORDER'].main,
  },
  INVOICE: {
    main: colors.orders.INVOICE.main,
  },
  TARGET: {
    main: colors.orders.TARGET.main,
  },
};

export const colorSalesCharts =['#00B9D5','orange','#F8D548', '#cf3476' , '#FA2272' , '#088F8F','#0096FF','#89CFF0','#F0FFFF'];
export const chartColor = (c) => am4core.color(c);
export const createChartColors = (colors=[]) => colors.map(chartColor);
export const chartColors = colorPalette.map(chartColor);

/**
 *
 * @param {NumberFormatType} type
 *
 * Example: {formatNumber: getChartNumberFormatOption('currency')}
 */
export const getChartNumberFormatOption = (type='currency') =>
  type === 'currency'
  ? "$#,###.##"
  : type === 'number'
  ? "#,###"
  : type === 'roundedCurrency'
  ? "$#,###."
  : ['percent', 'percentage'].includes(type)
  ? "###.##%"
  : {};

export const chartDefaultExportItems = [{
  "label": "...",
  "menu": [
    // { "type": "png", "label": "PNG" },
    { "type": "csv", "label": "CSV" },
    // { "type": "pdf", "label": "Pdf" },
  ]
}];

/**
 *
 * @param {ChartOptions} options
 */
export const generateBarChartOptions = ({
  data, series,
  xAxes, yAxes = [{ type: "ValueAxis", title: { text: "Totals" }, }],
  cursor = {}, legend = {}, hoverable = true, colors = { "list": colorPalette, },
  isSparkline = false, ...options
}) => {
  const otherOptions = {};
  const sparklineOptions = isSparkline ? {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    chartContainer: {
      minHeight: 50,
      minWidth: 50,
    },
  } : {};

  xAxes = xAxes.map(v => {
    if (isSparkline) {
      v = {
        ...v,
        title: undefined,
        cursorTooltipEnabled: false,
        renderer: {
          grid: {
            disabled: true,
            template: {
              disabled: true,
            },
          },
          labels: {
            disabled: true,
            template: {
              disabled: true,
            },
          }
        }
      };
    }
    return v;
  });
  yAxes = yAxes.map(v => {
    if (isSparkline) {
      v = {
        ...v,
        title: undefined,
        cursorTooltipEnabled: false,
        renderer: {
          ..._.get(v, 'renderer', {}),
          baseGrid: {
            disabled: true,
          },
          grid: {
            disabled: true,
            template: {
              disabled: true,
            },
          },
          labels: {
            disabled: true,
            template: {
              disabled: true,
            },
          }
        },
      };
    }
    return v;
  });
  series = series.map(v => {
    if (isSparkline) {
      v = {
        ...v,
        strokeWidth: 0,
        labels: { disabled: true, },
        ticks: { disabled: true, },
        bullets: _.map(_.get(v, 'bullets', []), (b) => ({
          ...b,
          type: "CircleBullet",
          circle: {
            opacity: 0,
            radius: 3,
            propertyFields: {
              opacity: "opacity"
            }
          }
        }))
      };
    }
    return v;
  });

  if (isSparkline) {
    cursor = {
      ...cursor,
      lineY: { disabled: true, },
    };
    legend = undefined;
  }

  if (options.formatNumber) {
    otherOptions["numberFormatter"] = {
      "numberFormat": options.formatNumber
    };
  }

  return {
    // data,
    series,
    xAxes, yAxes,
    cursor, legend,
    colors,
    hoverable,
    ...sparklineOptions,
    ...otherOptions,
  };
};

/**
 *
 * @param {Chart} chart
 * @param {ChartOptions} options
 */
export const createBarChart = (chart, {
  data, series, legend = true, isLegendCheckbox=false,renderOutside =false,
  xAxes, yAxes = [{ title: "Totals", }],
  isSparkline = false, minHeight = 50, minWidth = 50,
  strokeWidth, tooltipText = "{valueX.value}",
  cursorLineX = true, cursorLineY = true,
  lineSeries,onClickColumn = null,
  ...options
}) => {
  chart.hiddenState.properties.opacity = 0;
  chart.colors.list = options.chartColors || chartColors;
  if (options.chartColorsAnalytics) {
    chart.colors.list = options.chartColorsAnalytics.map(function(color) {
      return new am4core.color(color);
    });
  }
  chart.cursor = new am4charts.XYCursor();
  if (_.isBoolean(cursorLineX)) {
    chart.cursor.lineX.disabled = !cursorLineX;
  }
  if (_.isBoolean(cursorLineY)) {
    chart.cursor.lineY.disabled = !cursorLineY;
  }

  if (options.formatNumber) {
    chart.numberFormatter.numberFormat = options.formatNumber;
  }
  if(options.isExportable !== false){
  chart.exporting.menu.items = [{
    "label": "...",
    "menu": [
      // { "type": "png", "label": "PNG" },
      { "type": "csv", "label": "CSV" },
      // { "type": "pdf", "label": "Pdf" },
    ]
  }];
}

  xAxes.forEach(v => {
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = v.category;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.maxWidth = v.maxWidth || 55;
    categoryAxis.renderer.minGridDistance = 30;
    if (v.darkerGridLines) {
    categoryAxis.renderer.grid.template.stroke = "#000000";
    categoryAxis.renderer.grid.template.strokeWidth =  2;
    }
    if(v.disableGridTemplate) {
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.line.strokeOpacity = 1;
    categoryAxis.renderer.line.stroke = '#d3d3d3';
    }
    if (v.title) {
      categoryAxis.title.text = v.title;
    }
    if (_.isBoolean(v.cursorTooltipEnabled)) {
      categoryAxis.cursorTooltipEnabled = v.cursorTooltipEnabled;
    }
    if(v.cellStartLocation) {
      categoryAxis.renderer.cellStartLocation = v.cellStartLocation;

    }
    if(v.cellEndLocation) {
      categoryAxis.renderer.cellEndLocation = v.cellEndLocation;
    }
    
    if (isSparkline) {
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.labels.template.disabled = true;
      categoryAxis.cursorTooltipEnabled = false;
      categoryAxis.title.text = null;
    }
    if (v.showLabel) {
      categoryAxis.renderer.labels.template.disabled = false;
    }
  });

  yAxes.forEach(v => {
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (v.keepSelection) {
      valueAxis.keepSelection = true;
    }
    valueAxis.title.text = v.title;
    valueAxis.renderer.inside = true;
    if (v.renderOutside) {
      valueAxis.renderer.inside = false;
    }
    valueAxis.renderer.labels.template.disabled = true;
    if(v.disableGridTemplate) {
    valueAxis.renderer.grid.template.disabled = true;
    if(v.showAxis) {
    valueAxis.renderer.line.strokeOpacity =1;
    valueAxis.renderer.line.stroke = '#d3d3d3';
    }
    }

    if (_.isBoolean(v.cursorTooltipEnabled)) {
      valueAxis.cursorTooltipEnabled = v.cursorTooltipEnabled;
    }

    if (_.has(v, 'min') && _.isNumber(v.min)) {
      valueAxis.min = v.min;
    }

    if (isSparkline) {
      valueAxis.renderer.grid.template.disabled = true;
      valueAxis.renderer.baseGrid.disabled = true;
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.title.text = null;
    }

    if (v.showLabel) {
      valueAxis.renderer.labels.template.disabled = false;
    }
    if (v.darkerGridLines) {
    valueAxis.renderer.grid.template.stroke = "#000000";
    valueAxis.renderer.grid.template.strokeWidth =  2;
    }
   
  });

  series.forEach(v => {
    const chartSeries = chart.series.push(new am4charts.ColumnSeries());
    chartSeries.dataFields.valueY = v.valueY;
    chartSeries.dataFields.categoryX = v.categoryX;
    chartSeries.tooltipText = v.tooltipText || tooltipText;
    chartSeries.strokeWidth = v.strokeWidth || strokeWidth;
    chartSeries.sequencedInterpolation = true;
    chartSeries.tooltip.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    chartSeries.tooltip.keepTargetHover = true;
    chartSeries.tooltip.events.on("hit", function(ev) {
      onClickColumn && onClickColumn(ev.target.dataItem.dataContext,
        _.get(ev.target.dataItem, ['component', 'dataFields','valueY'], null),
        );
    }, this);
    chartSeries.columns.template.events.on("hit", function(ev) {
      onClickColumn && onClickColumn(ev.target.dataItem.dataContext,
        _.get(ev.target.dataItem, ['component', 'dataFields','valueY'], null),
        );
    }, this);
    if (v.hidden) {
      chartSeries.hidden = true;
    }
    if (v.name) { chartSeries.name = v.name;
    chartSeries.id = v.name; }
    if (v.stroke) {
      chartSeries.stroke = am4core.color(v.stroke);
    }
    if (v.fillColor) {
      chartSeries.columns.template.fill = am4core.color(v.fillColor);
    }
    if (v.fillPropertyOpacity) {
      chartSeries.columns.template.propertyFields.fillOpacity = v.fillPropertyOpacity;
    }
    if (v.fillOpacity) {
      chartSeries.fillOpacity = v.fillOpacity;
    }
    if(v.stacked) {
      chartSeries.stacked = true;
    }

    if (isSparkline) {
      // chartSeries.columns.template.fill = color;

      if (chartSeries.labels) {
        chartSeries.labels.template.disabled = true;
      }
      if (chartSeries.ticks) {
        chartSeries.ticks.template.disabled = true;
      }

      var bullet = chartSeries.bullets.push(new am4charts.CircleBullet());
      bullet.circle.opacity = 0;
      bullet.circle.propertyFields.opacity = "opacity";
      bullet.circle.radius = 3;
    }

    if (v.fillAdapter) {
      chartSeries.columns.template.adapter.add("fill", v.fillAdapter);
    }
    if (v.strokeAdapter) {
      chartSeries.columns.template.adapter.add("stroke", v.strokeAdapter);
    }
    //Make each column to be of a different color
  if (v.fillDifferentColors) {
    chartSeries.columns.template.events.once("inited", function(event) {
    event.target.fill = chart.colors.getIndex(event.target.dataItem.index);
    event.target.stroke = chart.colors.getIndex(event.target.dataItem.index);
    });
}
   
  });
 
  if (options.scrollbarY) {
    chart.scrollbarY = new am4core.Scrollbar();
  }

  if (options.scrollbarX) {
    chart.scrollbarX = new am4core.Scrollbar();
    if (options.scrollbarXStart) {
      chart.scrollbarX.start = options.scrollbarXStart;
    }
    if (options.scrollbarXEnd) {
      chart.scrollbarX.end = options.scrollbarXEnd;
    }
  }
  if (isSparkline) {
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;

    chart.padding(0, 0, 0, 0);
    chart.chartContainer.minHeight = 50;
    chart.chartContainer.minWidth = 50;
  }

  if (legend && !isSparkline) {
    chart.legend = new am4charts.Legend();

    if (isLegendCheckbox) {
    chart.legend.useDefaultMarker = true;
    const marker = chart.legend.markers.template;
    const markerColumn = marker.children.getIndex(0);

    const markerColumnActiveState = markerColumn.states.getKey("active");
    markerColumn.cornerRadius(3, 3, 3, 3);
    markerColumn.defaultState.properties.strokeWidth = 0;
     // Add custom checkmark
    const checkbox = marker.createChild(am4core.Label);
    checkbox.html="<span style='color:white ;padding-left:5px'> &#10004; <span>";

    const checkboxActiveState = checkbox.states.create("active");
    checkboxActiveState.properties.opacity = 0;
    }
    if (options.reverseLegend) {
      chart.legend.reverseOrder = true;
    }
  }

  lineSeries && lineSeries.forEach(v => {
    const chartSeries = chart.series.push(new am4charts.LineSeries());
    chartSeries.dataFields.valueY = v.valueY;
    chartSeries.dataFields.categoryX = v.categoryX;
    chartSeries.strokeWidth = 3;
    if (v.name) { chartSeries.name = v.name; }
    if (v.strokeDasharrayField) {
      chartSeries.propertyFields.strokeDasharray = v.strokeDasharrayField;
    }
    chartSeries.tooltip.label.textAlign = "middle";
    if(v.stroke) {
     chartSeries.stroke= am4core.color(v.stroke);
    }
    if (isSparkline) {
      if (chartSeries.labels) {
        chartSeries.labels.template.disabled = true;
      }
      if (chartSeries.ticks) {
        chartSeries.ticks.template.disabled = true;
      }
    }

   options.toggleInvoiceLegend && chart.events.on("datavalidated", function (ev) {
    var source = ev.target.data;
     if (source[0].hasOwnProperty('target')) {
      chartSeries.hiddenInLegend = false;
      chart.feedLegend();
     } else {
      chartSeries.hiddenInLegend = true;
      chart.feedLegend();
     }
    });
    options.toggleSalesLegend && chart.events.on("datavalidated", function (ev) {
      var source = ev.target.data;
       if (source[0].hasOwnProperty('sales_orders_target')) {
        chartSeries.hiddenInLegend = false;
        chart.feedLegend();
       } else {
        chartSeries.hiddenInLegend = true;
        chart.feedLegend();
       }
      });
    const bullet = chartSeries.bullets.push(new am4charts.Bullet());
    chartSeries.tooltipText = v.tooltipText || "[#fff font-size: 15px]{categoryX}:\n[/][#fff font-size: 20px]{valueY}";
    const circle = bullet.createChild(am4core.Circle);
    circle.radius = 4;
    circle.fill = am4core.color("#fff");
    circle.strokeWidth = 3;
  });
  return chart;
};

/**
 *
 * @param {Chart} chart
 * @param {ChartOptions} options
 */
 export const createHorizontalBarChart = (chart, {
  data, series, legend = false,isLegendCheckbox =false,
  isLegendInactive = false,
  xAxes, yAxes = [{ title: "Totals", }],
  isSparkline = false, minHeight = 50, minWidth = 50,
  strokeWidth, tooltipText = "{valueX.value}",
  cursorLineX = true, cursorLineY = true,
  lineSeries,
  ...options
}) => {
  chart.hiddenState.properties.opacity = 0;
  chart.cursor = new am4charts.XYCursor();
  if (_.isBoolean(cursorLineX)) {
    chart.cursor.lineX.disabled = !cursorLineX;
  }
  if (_.isBoolean(cursorLineY)) {
    chart.cursor.lineY.disabled = !cursorLineY;
  }
  if (options.formatNumber) {
    chart.numberFormatter.numberFormat = options.formatNumber;
  }
  if(options.isExportable !== false){
  chart.exporting.menu.items = [{
    "label": "...",
    "menu": [
      // { "type": "png", "label": "PNG" },
      { "type": "csv", "label": "CSV" },
      // { "type": "pdf", "label": "Pdf" },
    ]
  }];
}

xAxes.forEach(v => {
  const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = v.category;
  categoryAxis.renderer.grid.template.location = 0;
  if(v.truncate) {
    categoryAxis.renderer.labels.template.truncate = v.truncate;
  } else {
    categoryAxis.renderer.labels.template.wrap = true;
  }
  if (v.labelTooltipText) {
    categoryAxis.renderer.labels.template.tooltipText = v.labelTooltipText;
  }

  if (v.fontSize) {
    categoryAxis.renderer.labels.template.fontSize = v.fontSize;
  }
  if (v.labelWidth) {
    categoryAxis.renderer.labels.template.maxWidth = v.labelWidth;
  }
  categoryAxis.renderer.minGridDistance = 30;

  if (v.maxZoomCount) {
    categoryAxis.maxZoomCount = v.maxZoomCount;
  }

  if (v.disableGridTemplate) {
    categoryAxis.renderer.grid.template.disabled = true;
  if (v.showAxis) {
    categoryAxis.renderer.line.strokeOpacity = 1;
    categoryAxis.renderer.line.stroke = '#d3d3d3';
  }
  }
  if (v.title) {
    categoryAxis.title.text = v.title;
  }
  if (_.isBoolean(v.cursorTooltipEnabled)) {
    categoryAxis.cursorTooltipEnabled = v.cursorTooltipEnabled;
    }
  if (v.cellStartLocation) {
    categoryAxis.renderer.cellStartLocation = v.cellStartLocation;
    }
  if (v.cellEndLocation) {
    categoryAxis.renderer.cellEndLocation = v.cellEndLocation;
  }
  if (isSparkline) {
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.cursorTooltipEnabled = false;
    categoryAxis.title.text = null;
  }
  if (v.showLabel) {
    categoryAxis.renderer.labels.template.disabled = false;
  } else {
    categoryAxis.renderer.labels.template.disabled = true;
  }
  });

  yAxes.forEach(v => {
    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    if (v.showTitle) {
      valueAxis.title.text = v.title;
    }
    if (v.disableGridTemplate) {
      valueAxis.renderer.grid.template.disabled = true;
      if (v.showAxis) {
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.stroke = '#d3d3d3';
     }

    }
    if (_.isBoolean(v.cursorTooltipEnabled)) {
      valueAxis.cursorTooltipEnabled = v.cursorTooltipEnabled;
    }
    if (_.has(v, 'min') && _.isNumber(v.min)) {
      valueAxis.min = v.min;
    }
    if (isSparkline) {
      valueAxis.renderer.grid.template.disabled = true;
      valueAxis.renderer.baseGrid.disabled = true;
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.title.text = null;
    }
    if (v.showLabel) {
      valueAxis.renderer.labels.template.disabled = false;
    }
  });
  series.forEach(v => {
  chart.colors.list = colorSalesCharts.map(function(color) {
    return new am4core.color(color);
  });
  const chartSeries = chart.series.push(new am4charts.ColumnSeries());
  chartSeries.dataFields.valueX = v.valueY;
  chartSeries.dataFields.categoryY = v.categoryX;
  chartSeries.tooltip.label.maxWidth = 300;
  chartSeries.tooltip.label.wrap = true;
  chartSeries.tooltipText = v.tooltipText || tooltipText;
  chartSeries.strokeWidth = v.strokeWidth || strokeWidth;
  chartSeries.sequencedInterpolation = true;
  chartSeries.columns.width= 0;
  if (v.cornerRadius) {
    chartSeries.columns.template.column.cornerRadiusTopRight = v.cornerRadius;
    chartSeries.columns.template.column.cornerRadiusBottomRight= v.cornerRadius;
  }
  if(v.CountHeightChange) {
  chartSeries.columns.template.events.on("beforevalidated", function(event) {
     if(chart.data.length > v.CountHeightChange) {
     event.target.maxHeight = v.maxHeight;
     } else {
       event.target.maxHeight = v.maxHeight2;
     }
  });
}
else if (v.maxHeight) {
  chartSeries.columns.template.maxHeight = v.maxHeight;
}

 //Make each column to be of a different color
  if (v.fillDifferentColors) {
    chartSeries.columns.template.events.once("inited", function(event) {
    event.target.fill = chart.colors.getIndex(event.target.dataItem.index);
    event.target.stroke = chart.colors.getIndex(event.target.dataItem.index);
    });
}
  if (v.name) { chartSeries.name = v.name; }
  if (v.stroke) {
      chartSeries.stroke = am4core.color(v.stroke);
    }
  if (v.fillColor) {
    chartSeries.columns.template.fill = am4core.color(v.fillColor);
  }
  if (v.fillPropertyOpacity) {
    chartSeries.columns.template.propertyFields.fillOpacity = v.fillPropertyOpacity;
  }
  if (v.fillOpacity) {
    chartSeries.fillOpacity = v.fillOpacity;
  }
  if(v.stacked) {
    chartSeries.stacked = true;
  }
  if (v.isInsideBarLabel) {
  var valueLabel = chartSeries.columns.template.createChild(am4core.Label);
  valueLabel.text = v.insideBarLabelText;
  valueLabel.fontSize = 12;
  valueLabel.valign = "middle";
  valueLabel.dx = 10;
  valueLabel.strokeWidth = 0;
  valueLabel.maxWidth= 450;
  valueLabel.truncate=true;
  }
  if (isSparkline) {
    // chartSeries.columns.template.fill = color;
    if (chartSeries.labels) {
      chartSeries.labels.template.disabled = true;
    }
    if (chartSeries.ticks) {
      chartSeries.ticks.template.disabled = true;
    }
    var bullet = chartSeries.bullets.push(new am4charts.CircleBullet());
    bullet.circle.opacity = 0;
    bullet.circle.propertyFields.opacity = "opacity";
    bullet.circle.radius = 3;
  }

  if (v.fillAdapter) {
    chartSeries.columns.template.adapter.add("fill", v.fillAdapter);
  }
  if (v.strokeAdapter) {
    chartSeries.columns.template.adapter.add("stroke", v.strokeAdapter);
  }
  if (v.isScrollbarY) {
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.startGrip.disabled = true;
    chart.scrollbarY.endGrip.disabled = true;
    }
  });

  if (isSparkline) {
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;
    chart.padding(0, 0, 0, 0);
    chart.chartContainer.minHeight = 50;
    chart.chartContainer.minWidth = 50;
  }
  if (legend && !isSparkline) {
    chart.legend = new am4charts.Legend();
    if (isLegendCheckbox) {

      chart.legend.useDefaultMarker = true;
      const marker = chart.legend.markers.template;
      const markerColumn = marker.children.getIndex(0);
      const markerColumnActiveState = markerColumn.states.getKey("active");
      markerColumn.cornerRadius(3, 3, 3, 3);
      markerColumn.defaultState.properties.strokeWidth = 0;
       // Add custom checkmark
      const checkbox = marker.createChild(am4core.Label);
      checkbox.html="<span style='color:white ;padding-left:5px; '> &#10004; <span>";

      const checkboxActiveState = checkbox.states.create("active");
      checkboxActiveState.properties.opacity = 0;
      }
    if (isLegendInactive) {
      chart.legend.itemContainers.template.clickable = false;
      chart.legend.itemContainers.template.focusable = false;
      chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
    }
  }
  return chart;
};

/**
 *
 * @param {Chart} chart
 * @param {ChartOptions} options
 */
export const createBulletImageBarChart = (chart, {
  data, valueX, valueY, scrollY = false,
  imgHref, imgField, imgHeight = 50, imgWidth = 50, barHeight = 25,
  series2, tooltipText = "{valueX.value}",onClickColumn = null,
  hoverAnimation = false, fontSize=10,crnerRad1 = 60,crnerRad2 = 15,
  circleRadius = 20, outlineCircle = true, hideValueAxis = false, hideZoomOutBtn = false,hideCategoryAxisLabel = false,
  ...options
}) => {
  chart.hiddenState.properties.opacity = 0;
  if (hideZoomOutBtn) {
    chart.zoomOutButton.disabled = true;
  }
  chart.colors.list = chartColors;
  if (options.chartColorsAnalytics) {
    chart.colors.list = options.chartColorsAnalytics.map(function(color){
      return new am4core.color(color);
    });
  }
  chart.paddingLeft = -120;
  if (options.formatNumber) {
    chart.numberFormatter.numberFormat = options.formatNumber;
  }
  if(options.isExportable !== false){
    chart.exporting.menu.items = [{
      "label": "...",
      "menu": [
        // { "type": "png", "label": "PNG" },
        { "type": "csv", "label": "CSV" },
        // { "type": "pdf", "label": "Pdf" },
      ]
    }];
  }
  const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = valueY;
  categoryAxis.renderer.grid.template.strokeOpacity = 0;
  categoryAxis.renderer.minGridDistance = 12;
  categoryAxis.renderer.labels.template.dx = 180;
  categoryAxis.renderer.labels.template.dy = -20;
  categoryAxis.renderer.labels.template.align = 'left';
  categoryAxis.renderer.labels.template.fontSize = fontSize || 10;
  if (options.differentOpacity) {
    categoryAxis.renderer.labels.template.events.once("inited", function(event) {
      if (event.target.dataItem._dataContext) {
      event.target.fillOpacity = event.target.dataItem._dataContext.opacity;
      }
    });
}
  
  // categoryAxis.renderer.labels.template.wrap = true;
  categoryAxis.renderer.labels.template.maxWidth = 170;
  categoryAxis.renderer.maxWidth = 170;
  categoryAxis.renderer.minWidth = 160;
  categoryAxis.renderer.tooltip.dx = 140;
  categoryAxis.cursorTooltipEnabled = false;
  if (options.valueText) {
    categoryAxis.renderer.labels.template.adapter.add("text", (label, target, key) => {
      return options.valueText;
    });
  }
  if (hideCategoryAxisLabel) {
    categoryAxis.renderer.labels.template.disabled = true;
  }

  const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
  valueAxis.renderer.inside = true;
  valueAxis.renderer.labels.template.fillOpacity = 0.3;
  valueAxis.renderer.grid.template.strokeOpacity = 0;
  valueAxis.min = 0;
  valueAxis.cursorTooltipEnabled = false;
  valueAxis.renderer.baseGrid.strokeOpacity = 0;
  valueAxis.renderer.labels.template.dy = 20;
 if (options.scaleMax) {
  valueAxis.min = 0;
  valueAxis.max = options.scaleMax;
  valueAxis.strictMinMax = true;
  chart.invalidateData();
 }
  if (hideValueAxis) {
    valueAxis.renderer.labels.template.disabled = true;
  }
  
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.valueX = valueX;
  series.dataFields.categoryY = valueY;
  series.tooltipText = tooltipText;
  if (options.tooltipPointerOrientation) {
    series.tooltip.pointerOrientation = options.tooltipPointerOrientation;
  } else {
  series.tooltip.pointerOrientation = "vertical";
  }
  series.tooltip.dy = - 30;
  series.columnsContainer.zIndex = 100;
  if (options.mousePointer) {
  series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
  }
  series.columns.template.events.on("hit", function(ev) {
    onClickColumn && onClickColumn(ev.target.dataItem.dataContext,
      _.get(ev.target.dataItem, ['component', 'dataFields','valueY'], null),
      );
  }, this);

  if (options.insideBarLabelText) {
   // categoryAxis.renderer.labels.template.disabled = true;
    var valueLabel = series.columns.template.createChild(am4core.Label);
    valueLabel.text = options.insideBarLabelText;
    valueLabel.fontSize = 16;
    valueLabel.valign = "middle";
    valueLabel.dx = 35;
    valueLabel.strokeWidth = 0;
    valueLabel.maxWidth= 450;
    valueLabel.truncate=true;
    }

  const columnTemplate = series.columns.template;
  columnTemplate.height = am4core.percent(50);
  columnTemplate.maxHeight = barHeight;
  columnTemplate.column.cornerRadius(crnerRad1, crnerRad2, crnerRad1, crnerRad2);
  columnTemplate.strokeOpacity = 0;

  if (!options.chartColorsAnalytics) {
    series.heatRules.push({ target: columnTemplate, property: "fill", dataField: "valueX", min: am4core.color("#e5dc36"), max: am4core.color("#5faa46") });
  }
  series.mainContainer.mask = undefined;

  if (options.onHit) {
    columnTemplate.events.on("hit", options.onHit);
  }

  const cursor = new am4charts.XYCursor();
  chart.cursor = cursor;
  cursor.lineX.disabled = true;
  cursor.lineY.disabled = true;
  cursor.behavior = "none";

  const bullet = columnTemplate.createChild(am4charts.CircleBullet);
  bullet.circle.radius = circleRadius;
  bullet.valign = "middle";
  bullet.align = "left";
  bullet.isMeasured = true;
  bullet.interactionsEnabled = false;
  bullet.horizontalCenter = "right";
  bullet.interactionsEnabled = false;

  if (hoverAnimation) {
    bullet.states.create("hover");
  }
  if (outlineCircle) {
    const outlineCircle = bullet.createChild(am4core.Circle);
    outlineCircle.adapter.add("radius", function (radius, target) {
      const circleBullet = target.parent;
      return circleBullet.circle.pixelRadius + 5;
    });
}

  const image = bullet.createChild(am4core.Image);
  image.width = imgWidth;
  image.height = imgHeight;
  image.horizontalCenter = "middle";
  image.verticalCenter = "middle";
  image.clickable = true;
  if (imgHref) {
    image.href = imgHref;
  } else {
    image.propertyFields.href = imgField;
  }
  image.adapter.add("mask", function (mask, target) {
    const circleBullet = target.parent;
    return circleBullet.circle;
  });

  var cellSize = 60;
  if (options.autoAdjustHeight) {
    chart.events.on("datavalidated", function(ev) {
    var chart = ev.target;
    var categoryAxis = chart.yAxes.getIndex(0);
    var adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;
    var targetHeight = chart.pixelHeight + adjustHeight;
    chart.svgContainer.htmlElement.style.height = targetHeight + "px";
    });
  }

  if (series2) {
    const series_2 = chart.series.push(new am4charts.ColumnSeries());
    series_2.dataFields.valueX = series2.valueX;
    series_2.dataFields.categoryY = series2.valueY;
    series_2.tooltipText = series2.tooltipText || tooltipText || "{valueX.value}";
    series_2.tooltip.pointerOrientation = "down";
    series_2.tooltip.dy = -60;
    series_2.columnsContainer.zIndex = 100;
    series_2.heatRules.push({ target: columnTemplate, property: "fill", dataField: "valueX", min: am4core.color("#e5dc36"), max: am4core.color("#5faa46") });
    series_2.mainContainer.mask = undefined;
    series_2.columns.template.height = am4core.percent(10);
    series_2.columns.template.radius = 15;
    series_2.columns.template.column.cornerRadius(60, 10, 60, 10);

    series_2.clustered = false;
    series_2.columns.template.dx = 0;
    // series_2.columns.template.dy = -20;
  }

  if (hoverAnimation) {
    let previousBullet;
    chart.cursor.events.on("cursorpositionchanged", function (event) {
      if (data && data.length == 0) { return null; }
      try {
        const dataItem = series.tooltipDataItem;
        if (dataItem.column) {
          const bullet = dataItem.column.children.getIndex(1);
          if (previousBullet && previousBullet != bullet) {
            if (previousBullet._disposers && previousBullet._disposers.length > 0) {
              previousBullet.isHover = false;
            }
          }
          if (previousBullet != bullet && bullet && bullet.states) {
            const hs = bullet.states.getKey("hover");
            hs.properties.dx = dataItem.column.pixelWidth;
            bullet.isHover = true;
            previousBullet = bullet;
          }
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  if (scrollY) {
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.end = 10;
    chart.scrollbarY.parent = chart.rightAxesContainer;
  }

  return chart;
};

/**
 *
 * @param {Chart} chart
 * @param {ChartOptions} options
 */
export const createVerticalBulletImageBarChart = (chart, {
  data, valueX, valueY, scrollX = false,
  imgHref, imgField, imgHeight = 50, imgWidth = 50,
  tooltipText = "{valueY.value}",
  hoverAnimation = false, fontSize=10,
  ...options
}) => {
  chart.hiddenState.properties.opacity = 0;
  chart.colors.list = chartColors;
  chart.paddingBottom = 60;
  if (options.formatNumber) {
    chart.numberFormatter.numberFormat = options.formatNumber;
  }
  chart.exporting.menu.items = [{
    "label": "...",
    "menu": [
      // { "type": "png", "label": "PNG" },
      { "type": "csv", "label": "CSV" },
      // { "type": "pdf", "label": "Pdf" },
    ]
  }];

  var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = valueX;
  categoryAxis.renderer.grid.template.strokeOpacity = 0;
  categoryAxis.renderer.minGridDistance = 10;
  categoryAxis.renderer.labels.template.dy = -35;
  categoryAxis.renderer.tooltip.dy = 35;
  categoryAxis.cursorTooltipEnabled = false;
  categoryAxis.renderer.labels.template.fontSize = fontSize || 10;

  categoryAxis.renderer.labels.template.rotation = -90;
  categoryAxis.renderer.labels.template.horizontalCenter = "left";
  categoryAxis.renderer.labels.template.location = 0.5;
  categoryAxis.renderer.inside = true;

  categoryAxis.renderer.labels.template.adapter.add("dx", function (dx, target) {
    return -target.maxRight / 2;
  });

  var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.renderer.inside = true;
  valueAxis.renderer.labels.template.fillOpacity = 0.3;
  valueAxis.renderer.grid.template.strokeOpacity = 0;
  valueAxis.min = 0;
  valueAxis.cursorTooltipEnabled = false;
  valueAxis.renderer.baseGrid.strokeOpacity = 0;

  var series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.valueY = valueY;
  series.dataFields.categoryX = valueX;
  series.tooltipText = tooltipText;
  series.tooltip.pointerOrientation = "vertical";
  series.tooltip.dy = - 6;
  series.columnsContainer.zIndex = 100;

  var columnTemplate = series.columns.template;
  columnTemplate.width = am4core.percent(50);
  columnTemplate.maxWidth = 25;
  columnTemplate.column.cornerRadius(60, 60, 15, 15);
  columnTemplate.strokeOpacity = 0;

  series.heatRules.push({ target: columnTemplate, property: "fill", dataField: "valueY", min: am4core.color("#e5dc36"), max: am4core.color("#5faa46") });
  series.mainContainer.mask = undefined;

  var cursor = new am4charts.XYCursor();
  chart.cursor = cursor;
  cursor.lineX.disabled = true;
  cursor.lineY.disabled = true;
  cursor.behavior = "none";

  var bullet = columnTemplate.createChild(am4charts.CircleBullet);
  bullet.circle.radius = 20;
  bullet.valign = "bottom";
  bullet.align = "center";
  bullet.isMeasured = true;
  bullet.mouseEnabled = false;
  bullet.verticalCenter = "bottom";
  bullet.interactionsEnabled = false;

  if (hoverAnimation) {
    bullet.states.create("hover");
  }
  var outlineCircle = bullet.createChild(am4core.Circle);
  outlineCircle.adapter.add("radius", function (radius, target) {
    return target.parent.circle.pixelRadius + 5;
  });

  var image = bullet.createChild(am4core.Image);
  image.width = imgWidth;
  image.height = imgHeight;
  image.horizontalCenter = "middle";
  image.verticalCenter = "middle";
  if (imgHref) {
    image.href = imgHref;
  } else {
    image.propertyFields.href = imgField;
  }
  image.adapter.add("mask", function (mask, target) {
    return target.parent.circle;
  });

  if (hoverAnimation) {
    let previousBullet;
    chart.cursor.events.on("cursorpositionchanged", function (event) {
      if (data && data.length == 0) { return null; }
      try {
        const dataItem = series.tooltipDataItem;
        if (dataItem.column) {
          const bullet = dataItem.column.children.getIndex(1);
          if (previousBullet && previousBullet != bullet) {
            if (previousBullet._disposers && previousBullet._disposers.length > 0) {
              previousBullet.isHover = false;
            }
          }
          if (previousBullet != bullet && bullet && bullet.states) {
            const hs = bullet.states.getKey("hover");
            hs.properties.dy = -bullet.parent.pixelHeight + 30;
            bullet.isHover = true;
            previousBullet = bullet;
          }
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  if (scrollX) {
    chart.scrollX = new am4core.Scrollbar();
    chart.scrollX.end = 10;
    chart.scrollX.parent = chart.rightAxesContainer;
  }

  return chart;
};

/**
 *
 * @param {Chart} chart
 * @param {ChartOptions} options
 */
export const createTreeMapChart = (chart, {
  data, name, value, children = null,
  imgHref, imgField, imgHeight = 85, imgWidth = 85,
  tooltipText = "{name}: [bold]{value}",
  ...options
}) => {
  chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
  chart.maxLevels = 1;
  // chart.data = data;
  chart.dataFields.value = value;
  chart.dataFields.name = name;
  if (children) {
    chart.dataFields.children = children;
  }
  chart.zoomable = true;
  chart.colors.list = chartColors;
  if (options.formatNumber) {
    chart.numberFormatter.numberFormat = options.formatNumber;
  }
  chart.exporting.menu.items = [{
    "label": "...",
    "menu": [
      // { "type": "png", "label": "PNG" },
      { "type": "csv", "label": "CSV" },
      // { "type": "pdf", "label": "Pdf" },
    ]
  }];

  var level0Series = chart.seriesTemplates.create("0");
  level0Series.strokeWidth = 2;
  level0Series.tooltip.animationDuration = 0;
  level0Series.strokeOpacity = 1;

  level0Series.columns.template.tooltipText = tooltipText;

  var bgColor = new am4core.InterfaceColorSet().getFor("background");
  var level0Template = level0Series.columns.template;
  level0Template.fillOpacity = 1;
  level0Template.strokeWidth = 4;
  level0Template.stroke = bgColor;

  var image = level0Template.createChild(am4core.Image);
  image.opacity = 0.95;
  image.align = "center";
  image.valign = "middle";
  image.width = am4core.percent(imgWidth);
  image.height = am4core.percent(imgHeight);

  if (imgHref) {
    image.href = imgHref;
  } else {
    image.propertyFields.href = imgField;
  }

  var tableTypeSeries = chart.seriesTemplates.create("1");
  tableTypeSeries.columns.template.fillOpacity = 0;

  var tableTypeBullet = tableTypeSeries.bullets.push(new am4charts.LabelBullet());
  tableTypeBullet.locationX = 0.5;
  tableTypeBullet.locationY = 0.5;
  tableTypeBullet.label.text = "[font-size: 16px; bold]{name}[/]\n{value}";
  tableTypeBullet.label.fill = am4core.color("#ffffff");

  var paramSeries = chart.seriesTemplates.create("2");
  paramSeries.columns.template.fillOpacity = 0;

  var paramBullet = paramSeries.bullets.push(new am4charts.LabelBullet());
  paramBullet.locationX = 0.5;
  paramBullet.locationY = 0.5;
  paramBullet.label.text = "[font-size: 16px; bold]{name}[/]\n{value}";

  return chart;
};

/**
 *
 * @param {Chart} chart
 * @param {ChartOptions} options
 */
export const createForeceDirectedTreeChart = (chart, {
  data, name, value, children = null, linkWith = null,
  imgHref, imgField, imgHeight = 85, imgWidth = 85,
  tooltipText = "{name}: [bold]{value}",
  ...options
}) => {
  chart.hiddenState.properties.opacity = 0;

  if (options.formatNumber) {
    chart.numberFormatter.numberFormat = options.formatNumber;
  }
  chart.exporting.menu.items = [{
    "label": "...",
    "menu": [
      // { "type": "png", "label": "PNG" },
      { "type": "csv", "label": "CSV" },
      // { "type": "pdf", "label": "Pdf" },
    ]
  }];

  var networkSeries = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries());
  // chart.data = data;
  networkSeries.dataFields.value = value;
  networkSeries.dataFields.name = name;
  if (children) {
    networkSeries.dataFields.children = "children";
  }
  networkSeries.nodes.template.tooltipText = tooltipText;
  networkSeries.nodes.template.fillOpacity = 1;
  networkSeries.dataFields.id = "name";
  if (linkWith) {
    networkSeries.dataFields.linkWith = linkWith;
  }

  networkSeries.nodes.template.label.text = "{name}";
  networkSeries.fontSize = 10;

  var image = networkSeries.nodes.template.createChild(am4core.Image);
  image.opacity = 0.75;
  image.align = "center";
  image.valign = "middle";
  image.width = am4core.percent(imgWidth);
  image.height = am4core.percent(imgHeight);

  if (imgHref) {
    image.href = imgHref;
  } else {
    image.propertyFields.href = imgField;
  }

  var label = chart.createChild(am4core.Label);
  label.text = "{name}";
  label.x = 50;
  label.y = 50;
  label.isMeasured = false;

  var selectedNode;
  networkSeries.nodes.template.events.on("up", function (event) {
    var node = event.target;
    if (!selectedNode) {
      node.outerCircle.disabled = false;
      node.outerCircle.strokeDasharray = "3,3";
      selectedNode = node;
    } else if (selectedNode == node) {
      node.outerCircle.disabled = true;
      node.outerCircle.strokeDasharray = "";
      selectedNode = undefined;
    } else {
      var link = node.linksWith.getKey(selectedNode.uid);
      if (link) {
        node.unlinkWith(selectedNode);
      } else {
        node.linkWith(selectedNode, 0.2);
      }
    }
  });

  return chart;
};

/**
 *
 * @param {Chart} chart
 * @param {ChartOptions} options
 */
export function createDonutChart(chart, {
  data, value, category,radius,
  legend = true, legendPosition = "right",
  labelText = "{values.value.sum}",
  onClickSlice = null,
  ...options
}) {

  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = value;
  pieSeries.dataFields.category = category;
  pieSeries.labels.template.disabled = true;
  pieSeries.ticks.template.disabled = true;

  if (options.tooltipText) {
    pieSeries.slices.template.tooltipText = options.tooltipText;
  }

  if (options.formatNumber) {
    chart.numberFormatter.numberFormat = options.formatNumber;
  }
  if(options.isExportable !== false){
  chart.exporting.menu.items = [{
    "label": "...",
    "menu": [
      // { "type": "png", "label": "PNG" },
      { "type": "csv", "label": "CSV" },
      // { "type": "pdf", "label": "Pdf" },
    ]
  }];
}

  // pieSeries.labels.template.maxWidth = 130;
  // pieSeries.labels.template.wrap = true;
  // pieSeries.labels.template.text = "{category}: [bold]{value}";

  // add onlick event on slice
  if (onClickSlice && _.isFunction(onClickSlice)) {
    pieSeries.slices.template.events.on("hit", function (event) {
      onClickSlice(event, chart);
    });
  }

  chart.seriesContainer.zIndex = -1;

  if (legend) {
    chart.legend = new am4charts.Legend();
    chart.legend.position = legendPosition || "right";
    chart.legend.labels.template.text = "{category}";
  }

  chart.innerRadius = am4core.percent(radius);

  var container = new am4core.Container();
  container.parent = pieSeries;
  container.horizontalCenter = "middle";
  container.verticalCenter = "middle";
  container.width = am4core.percent(40) / Math.sqrt(2);
  container.fill = "white";

  var label = new am4core.Label();
  label.parent = container;
  label.text = labelText;
  label.horizontalCenter = "middle";
  label.verticalCenter = "middle";
  label.fontSize = 30;
  label.wrap = true;
  label.maxWidth = 170;
  label.textAlign = "middle";

  // chart.events.on("sizechanged", function(ev) {
  //   var scale = (pieSeries.pixelInnerRadius * 2) / label.bbox.width;
  //   if (scale > 1) {
  //     scale = 1;
  //   }
  //   label.scale = scale;
  // })
  return chart;
}

//test//

export function createDonutChartSales(chart, {
  data, value, category,radius,color, titleText,
  labelText = "${values.value.sum}",
  onClickSlice = null,
  ...options
}) {
  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = value;
  pieSeries.dataFields.category = category;
  pieSeries.slices.template.stroke = am4core.color("#fff");
  pieSeries.slices.template.strokeWidth = 2;
  pieSeries.slices.template.strokeOpacity = 1;
  pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0;

   var colorSet= new am4core.ColorSet();
   colorSet.list = colorSalesCharts.map(function(color) {
     return new am4core.color(color);
   });
  pieSeries.colors = colorSet;

 /* var rgm = new am4core.RadialGradientModifier();
  rgm.brightnesses.push(0, 0, 0, 0, 0);
  pieSeries.slices.template.fillModifier = rgm;
  pieSeries.slices.template.strokeModifier = rgm;
  pieSeries.slices.template.strokeOpacity = 0;
  pieSeries.slices.template.strokeWidth = 0;
  pieSeries.slices.template.propertyFields.fill = color;*/

  if (options.tooltipText) {
    pieSeries.slices.template.tooltipText = options.tooltipText;
  }

  if (options.formatNumber) {
    chart.numberFormatter.numberFormat = options.formatNumber;
  }
  if(options.isExportable !== false){
  chart.exporting.menu.items = [{
    "label": "...",
    "menu": [
      // { "type": "png", "label": "PNG" },
      { "type": "csv", "label": "CSV" },
      // { "type": "pdf", "label": "Pdf" },
    ]
  }];
}
  pieSeries.labels.template.fontSize = 13;
  pieSeries.labels.template.maxWidth = 130;
  pieSeries.labels.template.wrap = true;
  pieSeries.labels.template.text = "{category}\n[bold]${value}";

  pieSeries.labels.template.adapter.add("text", function(text, target) {
    if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
      return "";
    }
    return text;
  });

  chart.events.on("datavalidated", function (ev) {
  var source = ev.target.data;
  const index = source.findIndex(x=>x.hasOwnProperty('sliceGrouperOther'));
  if(index > -1) {
    source.splice(index,1);
  }
  });

  var grouper = pieSeries.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
  grouper.threshold = 5;
  grouper.groupName = "Other";
  grouper.clickBehavior = "zoom";
  pieSeries.slices.template.events.on("hit", function(ev){
    let slice = ev.target;
    if(ev.target.dataItem.category === 'Other') {
      slice.isActive = !slice.isActive;
    }
  });

  pieSeries.slices.template.events.on("hit", function(ev) {
    onClickSlice && onClickSlice(ev.target.dataItem.dataContext,
      _.get(ev.target.dataItem, ['component', 'dataFields','valueY'], null),
      );
  }, this);
  chart.seriesContainer.zIndex = -1;
  chart.innerRadius = am4core.percent(radius);

  var container = new am4core.Container();
  container.parent = pieSeries;
  container.horizontalCenter = "middle";
  container.verticalCenter = "middle";
  container.width = am4core.percent(60) / Math.sqrt(2);
  container.fill = "white";
  var label = new am4core.Label();
  label.parent = container;
  label.text = labelText;
  label.horizontalCenter = "middle";
  label.verticalCenter = "middle";
  label.fontSize = 25;
  label.wrap = true;
  label.maxWidth = 170;
  label.textAlign = "middle";

  var title = chart.titles.create();
  title.text = titleText;
  title.fontSize = 20;

  return chart;
}

/**
 *
 * @param {Chart} chart
 * @param {ChartOptions} options
 */
export function updateDonutChart(chart, {
  data, value, category,
  legend = true, legendPosition = "right",
  labelText = "{values.value.sum}",
  onClickSlice,
  ...options
}) {
  const series = chart.series.getIndex(0);
  if (!series || (
    series && series.dataFields.value == value && series.dataFields.category == category
  )) {
    return chart;
  }
  chart.exporting.menu.items = [{
    "label": "...",
    "menu": [
      // { "type": "png", "label": "PNG" },
      { "type": "csv", "label": "CSV" },
      // { "type": "pdf", "label": "Pdf" },
    ]
  }];

  chart.series.removeIndex(0).dispose();

  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = value;
  pieSeries.dataFields.category = category;
  pieSeries.labels.template.disabled = true;
  pieSeries.ticks.template.disabled = true;
  chart.seriesContainer.zIndex = -1;

  if (options.tooltipText) {
    pieSeries.slices.template.tooltipText = options.tooltipText;
  }

  if (options.formatNumber) {
    chart.numberFormatter.numberFormat = options.formatNumber;
  }

  // add onlick event on slice
  if (onClickSlice && _.isFunction(onClickSlice)) {
    pieSeries.slices.template.events.on("hit", function (event) {
      onClickSlice(event, chart);
    });
  }

  var container = new am4core.Container();
  container.parent = pieSeries;
  container.horizontalCenter = "middle";
  container.verticalCenter = "middle";
  container.width = am4core.percent(40) / Math.sqrt(2);
  container.fill = "white";

  var label = new am4core.Label();
  label.parent = container;
  label.text = labelText;
  label.horizontalCenter = "middle";
  label.verticalCenter = "middle";
  label.fontSize = 30;
  label.wrap = true;
  label.maxWidth = 170;
  label.textAlign = "middle";

  return chart;
}

/**
 *
 * @param {Chart} chart
 * @param {ChartOptions} options
 */
export function createPieInPieChart(container, {
  data, value, category,
  sub_value, sub_category,
  legend = false, legendPosition = "right",
  labelText = "{values.value.sum}",
  ...options
}) {
  container.width = am4core.percent(100);
  container.height = am4core.percent(100);
  container.layout = "horizontal";

  var chart = container.createChild(am4charts.PieChart);
  chart.data = data;
  chart.colors.list = chartColors;

  chart.exporting.menu.items = [{
    "label": "...",
    "menu": [
      // { "type": "png", "label": "PNG" },
      { "type": "csv", "label": "CSV" },
      // { "type": "pdf", "label": "Pdf" },
    ]
  }];

  // Add and configure Series
  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = value;
  pieSeries.dataFields.category = category;
  pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0;
  //pieSeries.labels.template.text = "{category}\n{value.percent.formatNumber('#.#')}%";

  if (options.tooltipText) {
    pieSeries.slices.template.tooltipText = options.tooltipText;
  }

  if (options.formatNumber) {
    chart.numberFormatter.numberFormat = options.formatNumber;
  }

  pieSeries.slices.template.events.on("hit", function (event) {
    selectSlice(event.target.dataItem);
  });

  var chart2 = container.createChild(am4charts.PieChart);
  chart2.width = am4core.percent(30);
  chart2.radius = am4core.percent(80);

  chart2.colors.list = chartColors;

  // Add and configure Series
  var pieSeries2 = chart2.series.push(new am4charts.PieSeries());
  pieSeries2.dataFields.value = sub_value;
  pieSeries2.dataFields.category = sub_category;
  pieSeries2.slices.template.states.getKey("active").properties.shiftRadius = 0;
  //pieSeries2.labels.template.radius = am4core.percent(50);
  //pieSeries2.labels.template.inside = true;
  //pieSeries2.labels.template.fill = am4core.color("#ffffff");
  pieSeries2.labels.template.disabled = true;
  pieSeries2.ticks.template.disabled = true;
  pieSeries2.alignLabels = false;
  pieSeries2.events.on("positionchanged", updateLines);

  var interfaceColors = new am4core.InterfaceColorSet();

  var line1 = container.createChild(am4core.Line);
  line1.strokeDasharray = "2,2";
  line1.strokeOpacity = 0.5;
  line1.stroke = interfaceColors.getFor("alternativeBackground");
  line1.isMeasured = false;

  var line2 = container.createChild(am4core.Line);
  line2.strokeDasharray = "2,2";
  line2.strokeOpacity = 0.5;
  line2.stroke = interfaceColors.getFor("alternativeBackground");
  line2.isMeasured = false;

  var selectedSlice;

  function selectSlice(dataItem) {
    selectedSlice = dataItem.slice;

    // var fill = selectedSlice.fill;

    const subData = _.get(dataItem.dataContext, 'subData', []);
    // var count = subData.length;
    // pieSeries2.colors.list = chartColors;
    // pieSeries2.colors.list = [];
    // for (var i = 0; i < count; i++) {
    //   pieSeries2.colors.list.push(fill.brighten(i * 4 / (count-1)));
    // }

    chart2.data = subData;
    pieSeries2.appear();

    var middleAngle = selectedSlice.middleAngle;
    var firstAngle = pieSeries.slices.getIndex(0).startAngle;
    var animation = pieSeries.animate([{ property: "startAngle", to: firstAngle - middleAngle }, { property: "endAngle", to: firstAngle - middleAngle + 360 }], 600, am4core.ease.sinOut);
    animation.events.on("animationprogress", updateLines);

    selectedSlice.events.on("transformed", updateLines);

    //  var animation = chart2.animate({property:"dx", from:-container.pixelWidth / 2, to:0}, 2000, am4core.ease.elasticOut)
    //  animation.events.on("animationprogress", updateLines)
  }

  function updateLines() {
    if (selectedSlice) {
      var p11 = { x: selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle), y: selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle) };
      var p12 = { x: selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle + selectedSlice.arc), y: selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle + selectedSlice.arc) };

      p11 = am4core.utils.spritePointToSvg(p11, selectedSlice);
      p12 = am4core.utils.spritePointToSvg(p12, selectedSlice);

      var p21 = { x: 0, y: -pieSeries2.pixelRadius };
      var p22 = { x: 0, y: pieSeries2.pixelRadius };

      p21 = am4core.utils.spritePointToSvg(p21, pieSeries2);
      p22 = am4core.utils.spritePointToSvg(p22, pieSeries2);

      line1.x1 = p11.x;
      line1.x2 = p21.x;
      line1.y1 = p11.y;
      line1.y2 = p21.y;

      line2.x1 = p12.x;
      line2.x2 = p22.x;
      line2.y1 = p12.y;
      line2.y2 = p22.y;
    }
  }

  chart.events.on("datavalidated", function () {
    setTimeout(function () {
      selectSlice(pieSeries.dataItems.getIndex(0) || []);
    }, 1000);
  });

  if (legend) {
    chart.legend = new am4charts.Legend();
    chart.legend.position = legendPosition || "right";
    chart.legend.labels.template.text = "{category}";
  }

  return chart;
}

/**
 *
 * @param {Chart} chart
 * @param {ChartOptions} options
 */
export function createPieChart(chart, {
  data, value, category,
  legend = true, legendPosition = "right",
  labelText = "{values.value.sum}",
  onClickSlice = null,
  showLabel=false,
  ...options
}) {

  chart.exporting.menu.items = [{
    "label": "...",
    "menu": [
      // { "type": "png", "label": "PNG" },
      { "type": "csv", "label": "CSV" },
      // { "type": "pdf", "label": "Pdf" },
    ]
  }];

  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = value;
  pieSeries.dataFields.category = category;
  if (options.enableHidden) {
    pieSeries.dataFields.hidden = "show";
  }
  if (!options.showTicks) {
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
  }
  if (options.showSliceLabel) {
    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    pieSeries.labels.template.radius = am4core.percent(-40);
    pieSeries.labels.template.fill = am4core.color("white");
    if (options.sliceLabelRotate) {
      pieSeries.labels.template.relativeRotation = 90;
    }
  }
  if (options.strokeColor) {
    pieSeries.slices.template.stroke = am4core.color(options.strokeColor);
  }
  if (options.strokeWidth) {
    pieSeries.slices.template.strokeWidth = options.strokeWidth;
  }
  if (_.has(options, 'strokeOpacity')) {
    pieSeries.slices.template.strokeOpacity = options.strokeOpacity;
  }

  // This creates initial animation
  pieSeries.hiddenState.properties.opacity = 1;
  pieSeries.hiddenState.properties.endAngle = -90;
  pieSeries.hiddenState.properties.startAngle = -90;

  if (options.tooltipText) {
    pieSeries.slices.template.tooltipText = options.tooltipText;
  }

  if (options.formatNumber) {
    chart.numberFormatter.numberFormat = options.formatNumber;
  }
   if (options.mousePointer) {
    pieSeries.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
   }
  // add onlick event on slice
  if (onClickSlice && _.isFunction(onClickSlice)) {
    pieSeries.slices.template.events.on("hit", function (event) {
      onClickSlice(event, chart);
    });
  }

  chart.seriesContainer.zIndex = -1;

  if (legend) {
    chart.legend = new am4charts.Legend();
    chart.legend.position = legendPosition || "right";
    chart.legend.labels.template.text = "{category}";
    if (options.legendMaxHeight) {
      chart.legend.maxHeight = options.legendMaxHeight;
    }
    if (options.legendScrollable) {
      chart.legend.scrollable = true;
    }
     if (options.onClickLegend && _.isFunction(options.onClickLegend)) {
      chart.legend.itemContainers.template.events.on("hit", function(ev){
        options.onClickLegend(ev, chart)
      })
     }
    if (!options.clickableLegend) {
      chart.legend.itemContainers.template.togglable = false;
      chart.legend.itemContainers.template.events.on("hit", function(ev) {
        var slice = ev.target.dataItem.dataContext.slice;
        slice.isActive = !slice.isActive;
      });
    }
  }

  if (!options.shiftOnClick) {
    pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0;
  }

  // chart.innerRadius = am4core.percent(50);

  var container = new am4core.Container();
  container.parent = pieSeries;
  container.horizontalCenter = "middle";
  container.verticalCenter = "middle";
  container.width = am4core.percent(40) / Math.sqrt(2);
  container.fill = "white";

  if (showLabel) {
    var label = new am4core.Label();
    label.parent = container;
    label.text = labelText;
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 30;
    label.wrap = true;
    label.maxWidth = 170;
    label.textAlign = "middle";
  }

  return chart;
}

/**
 *
 * @param {Chart} chart
 * @param {ChartOptions} options
 */
export function updatePieChart(chart, {
  data, value, category,
  legend = true, legendPosition = "right",
  labelText = "{values.value.sum}",
  onClickSlice,
  ...options
}) {
  const series = chart.series.getIndex(0);
  if (!series || (
    series && series.dataFields.value == value && series.dataFields.category == category
  )) {
    return chart;
  }

  chart.series.removeIndex(0).dispose();

  chart.exporting.menu.items = [{
    "label": "...",
    "menu": [
      // { "type": "png", "label": "PNG" },
      { "type": "csv", "label": "CSV" },
      // { "type": "pdf", "label": "Pdf" },
    ]
  }];

  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = value;
  pieSeries.dataFields.category = category;
  if (!options.showTicks) {
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
  }
  if (options.showSliceLabel) {
    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    pieSeries.labels.template.radius = am4core.percent(-40);
    pieSeries.labels.template.fill = am4core.color("white");
    if (options.sliceLabelRotate) {
      pieSeries.labels.template.relativeRotation = 90;
    }
  }
  pieSeries.slices.template.stroke = am4core.color("#fff");
  // pieSeries.slices.template.strokeWidth = 2;
  // pieSeries.slices.template.strokeOpacity = 1;

  // This creates initial animation
  pieSeries.hiddenState.properties.opacity = 1;
  pieSeries.hiddenState.properties.endAngle = -90;
  pieSeries.hiddenState.properties.startAngle = -90;

  chart.seriesContainer.zIndex = -1;

  if (options.tooltipText) {
    pieSeries.slices.template.tooltipText = options.tooltipText;
  }

  if (options.formatNumber) {
    chart.numberFormatter.numberFormat = options.formatNumber;
  }

  // add onlick event on slice
  if (onClickSlice && _.isFunction(onClickSlice)) {
    pieSeries.slices.template.events.on("hit", function (event) {
      onClickSlice(event, chart);
    });
  }

  var container = new am4core.Container();
  container.parent = pieSeries;
  container.horizontalCenter = "middle";
  container.verticalCenter = "middle";
  container.width = am4core.percent(40) / Math.sqrt(2);
  container.fill = "white";

  var label = new am4core.Label();
  label.parent = container;
  label.text = labelText;
  label.horizontalCenter = "middle";
  label.verticalCenter = "middle";
  label.fontSize = 30;
  label.wrap = true;
  label.maxWidth = 170;
  label.textAlign = "middle";

  return chart;
}

/**
 *
 * @param {Chart} chart
 * @param {ChartOptions} options
 */

export const createLineChart = (chart, {
  data, series, legend = true,
  xAxes, yAxes = [{ title: "Totals", }],
  isSparkline = false, minHeight = 50, minWidth = 50,
  strokeWidth, tooltipText = "{valueX.value}",
  cursorLineX = true, cursorLineY = true, isLegendCheckbox = false,
  ...options
}) => {
  chart.hiddenState.properties.opacity = 0;
  chart.colors.list = options.chartColors || chartColors;
  chart.cursor = new am4charts.XYCursor();
  if (_.isBoolean(cursorLineX)) {
    chart.cursor.lineX.disabled = !cursorLineX;
  }
  if (_.isBoolean(cursorLineY)) {
    chart.cursor.lineY.disabled = !cursorLineY;
  }

  if (options.formatNumber) {
    chart.numberFormatter.numberFormat = options.formatNumber;
  }
  if(options.isExportable !== false){
  chart.exporting.menu.items = [{
    "label": "...",
    "menu": [
      // { "type": "png", "label": "PNG" },
      { "type": "csv", "label": "CSV" },
      // { "type": "pdf", "label": "Pdf" },
    ]
  }];
  }
  xAxes && xAxes.forEach(v => {
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = v.category;
    if (v.cellStartLocation) {
      categoryAxis.startLocation = v.cellStartLocation;
    }
    if (v.cellEndLocation) {
      categoryAxis.endLocation = v.cellEndLocation;
    }
    
    if (v.title) {
      categoryAxis.title.text = v.title;
    }

    if (_.isBoolean(v.cursorTooltipEnabled)) {
      categoryAxis.cursorTooltipEnabled = v.cursorTooltipEnabled;
    }

    if (v.showGrid === false) {
      categoryAxis.renderer.grid.template.disabled = true;
    }

    if (v.showAxis === false) {
      categoryAxis.renderer.labels.template.disabled = true;
    }
    if (v.showTitle === false) {
      categoryAxis.title.text = null;
    }
    if (v.darkerGridLines) {
      categoryAxis.renderer.grid.template.stroke = "#000000";
      categoryAxis.renderer.grid.template.strokeWidth =  2;
      }

    if (isSparkline) {
      categoryAxis.cursorTooltipEnabled = false;

      if (!v.showGrid) {
        categoryAxis.renderer.grid.template.disabled = true;
      }
      if (!v.showAxis) {
        categoryAxis.renderer.labels.template.disabled = true;
      }
      if (!v.showTitle) {
        categoryAxis.title.text = null;
      }
      if (v.showLabel) {
        categoryAxis.renderer.labels.template.disabled = false;
      }
    }

    categoryAxis.strictMinMax = false;
  });

  yAxes && yAxes.forEach(v => {
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = v.title;
    valueAxis.renderer.baseGrid.disabled = true;
    if(v.keepSelection) {
      valueAxis.keepSelection = true;
    }
    if (v.minGridDistance && _.isNumber(v.minGridDistance)) {
      valueAxis.renderer.minGridDistance = v.minGridDistance;
    }

    if (_.isBoolean(v.cursorTooltipEnabled)) {
      valueAxis.cursorTooltipEnabled = v.cursorTooltipEnabled;
    }
    if (_.has(v, 'max') && _.isNumber(v.max)) {
      valueAxis.max = v.max;
    }
    if (v.showGrid === false) {
      valueAxis.renderer.grid.template.disabled = true;
    }
    if (v.darkerGridLines) {
      valueAxis.renderer.grid.template.stroke = "#000000";
      valueAxis.renderer.grid.template.strokeWidth =  2;
    }
    if (v.showAxis === false) {
      valueAxis.renderer.labels.template.disabled = true;
    }
    if (v.showTitle === false) {
      valueAxis.title.text = null;
    }
    if (_.has(v, 'min') && _.isNumber(v.min)) {
      valueAxis.min = v.min;
    }

    if (isSparkline) {
      valueAxis.cursorTooltipEnabled = false;
      if (!v.showGrid) {
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.baseGrid.disabled = true;
      }
      if (!v.showAxis) {
        valueAxis.renderer.labels.template.disabled = true;
      }
      if (!v.showTitle) {
        valueAxis.title.text = null;
      }
    }
  });

  series.forEach(v => {
    const chartSeries = chart.series.push(new am4charts.LineSeries());
    chartSeries.dataFields.valueY = v.valueY;
    chartSeries.dataFields.categoryX = v.categoryX;
    chartSeries.tooltipText = v.tooltipText || tooltipText;
    chartSeries.strokeWidth = 3;
    chartSeries.tooltip.label.textAlign = "middle";
    // chartSeries.connect = true;
    if (v.hidden) {
      chartSeries.hidden = true;
    }
    if (v.name) { chartSeries.name = v.name; }
    if (v.stroke) {
      chartSeries.stroke = am4core.color(v.stroke);
    }
    if (v.fillOpacity) {
      chartSeries.fillOpacity = v.fillOpacity;
    }
    if (v.strokeDasharrayField) {
      chartSeries.propertyFields.strokeDasharray = v.strokeDasharrayField;
    }
    if (isSparkline) {
      if (chartSeries.labels) {
        chartSeries.labels.template.disabled = true;
      }
      if (chartSeries.ticks) {
        chartSeries.ticks.template.disabled = true;
      }
    }

    const bullet = chartSeries.bullets.push(new am4charts.Bullet());
    bullet.tooltipText = v.tooltipText || "[#fff font-size: 15px]{categoryX}:\n[/][#fff font-size: 20px]{valueY}";
    const circle = bullet.createChild(am4core.Circle);
    circle.radius = 4;
    circle.fill = am4core.color("#fff");
    circle.strokeWidth = 3;
  });

  if (isSparkline) {
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;

    chart.padding(0, 0, 0, 0);
    chart.chartContainer.minHeight = 50;
    chart.chartContainer.minWidth = 50;
  }

  if ((legend && !isSparkline) || options.forceLegend) {
    chart.legend = new am4charts.Legend();
    if (isLegendCheckbox) {
      chart.legend.useDefaultMarker = true;
      const marker = chart.legend.markers.template; 
      const markerColumn = marker.children.getIndex(0);
    
      const markerColumnActiveState = markerColumn.states.getKey("active");
      markerColumn.cornerRadius(3, 3, 3, 3);
      markerColumn.defaultState.properties.strokeWidth = 0;
       // Add custom checkmark
      const checkbox = marker.createChild(am4core.Label);
      checkbox.html="<span style='color:white ;padding-left:5px'> &#10004; <span>"
  
      const checkboxActiveState = checkbox.states.create("active");
      checkboxActiveState.properties.opacity = 0;
      }
    if (options.reverseLegend) {
      chart.legend.reverseOrder = true;
    }
  }
 
  if (options.scrollbarY) {
    chart.scrollbarY = new am4core.Scrollbar();
  }

  if (options.scrollbarX) {
    chart.scrollbarX = new am4core.Scrollbar();
    if (options.scrollbarXStart) {
      chart.scrollbarX.start = options.scrollbarXStart;
    }
    if (options.scrollbarXEnd) {
      chart.scrollbarX.end = options.scrollbarXEnd;
    }
  }

  return chart;
};
